import React from 'react'
import Nav from 'react-bootstrap/Nav'
import LoginIcon from '../Icons/LoginIcon'
import LogoutIcon from '../Icons/LogoutIcon'
import { useHistory } from 'react-router-dom'


const LogInLogOut = (props) => {

const history = useHistory()

const logoutUser = () => {
  history.push('/')
  localStorage.removeItem('tokens')
  localStorage.setItem('tokenStatus', JSON.stringify(false))
}

 
  return props.token ? (
    <Nav.Item name='logout' className='alignment' onClick={() => logoutUser()}><LogoutIcon height='1.5em' width='1.5em' fill='#FFA500'></LogoutIcon><Nav.Link style={{color:'#d8d8de'}}  href='/'>&ensp;Logout</Nav.Link></Nav.Item>
  ) : <Nav.Item className='alignment'><LoginIcon height='2em' width='2em' fill='#FFA500'></LoginIcon><Nav.Link style={{color:'#d8d8de'}}  href='/login'>&ensp;Login</Nav.Link></Nav.Item>
}

export default LogInLogOut



