import React from 'react'
import PropTypes from 'prop-types'

function LogoutIcon({ className, fill,  width, height  }) {
  return (<svg fill={fill} className = {className} width = {width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' x='0px' y='0px'><path d='M9,60H44a5,5,0,0,0,5-5V52.83a1,1,0,0,0-2,0V55a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V9A3,3,0,0,1,9,6H44a3,3,0,0,1,3,3v2.5a1,1,0,0,0,2,0V9a5,5,0,0,0-5-5H9A5,5,0,0,0,4,9V55A5,5,0,0,0,9,60Z'></path><circle cx='21' cy='23' r='8'></circle><path d='M10,39v9a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V39a5,5,0,0,0-5-5H15A5,5,0,0,0,10,39Z'></path><path d='M47.6,42.91a1,1,0,0,0,1.08-.17l11-10a1,1,0,0,0,0-1.48l-11-10A1,1,0,0,0,47,22v5H38a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h9v5A1,1,0,0,0,47.6,42.91Z'></path></svg>
    
  )
}

LogoutIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

LogoutIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  width: '2em',
  height: '2em',
}

export default LogoutIcon

