import React from 'react'
import PropTypes from 'prop-types'

function UserIcon({ className, fill, height, width }) {
  return (
    <svg fill={fill} className={className} height={height} width={width} xmlns='http://www.w3.org/2000/svg' data-name='Layer 2' viewBox='0 0 100 100' x='0px' y='0px'><path d='M50,41A16,16,0,1,1,66,25,16.05,16.05,0,0,1,50,41Z'></path><path d='M49.94,91.07a37.47,37.47,0,0,1-19-5.39,1.5,1.5,0,0,1-.67-1.25v-20a19.73,19.73,0,0,1,39.45,0v20a1.5,1.5,0,0,1-.67,1.25A37.65,37.65,0,0,1,49.94,91.07Zm18.28-6.65h0Z'></path></svg>  
  )
}

UserIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

UserIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default UserIcon