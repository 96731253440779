import React from 'react'
import PropTypes from 'prop-types'

function PingIcon({ height, width, fill, className }) {
  return (
      <svg fill={fill} height={height} width={width} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
        <path d="m1077.3 786h-140.29v-84c0-26.859-21.984-48.891-48.891-48.891h-259.26v-89.156h106.36c117.33-0.60938 149.02-163.03 40.266-207.32-27.469-61.5-109.59-73.922-155.76-31.219-11.203 10.359-28.547 10.125-39.375-0.65625-11.578-11.531-11.062-30.375 0.9375-41.484 14.531-13.453 31.641-24 50.25-30.938-80.203-50.344-191.81 7.4531-197.76 101.39-116.53 37.641-92.016 208.03 31.5 210.19h106.31v89.156h-259.08c-27.094 0-49.078 21.938-49.078 48.891v84h-140.72c-26.344 0-47.719 21.375-47.719 47.719v84.75c0 26.344 21.375 47.719 47.719 47.719h337.74c26.25 0 47.719-21.469 47.719-47.719v-84.75c0-26.344-21.562-47.719-47.719-47.719h-139.74v-75.609h558.98v75.609h-140.11c-26.344 0-47.719 21.375-47.719 47.719v84.75c0 26.344 21.375 47.719 47.719 47.719h337.74c26.344 0 47.719-21.375 47.719-47.719v-84.75c0-26.344-21.375-47.719-47.719-47.719zm-806.58 90.094c-0.46875 25.078-37.688 25.078-38.203 0 0.46875-25.078 37.688-25.078 38.203 0zm-91.078-19.078c25.078 0.46875 25.078 37.688 0 38.203-25.078-0.46875-25.078-37.688 0-38.203zm707.9 19.078c-0.46875 25.078-37.688 25.078-38.203 0 0.46875-25.078 37.688-25.078 38.203 0zm-91.078-19.078c25.078 0.46875 25.078 37.688 0 38.203-25.078-0.46875-25.078-37.688 0-38.203z"/>
      </svg>
    )
}

PingIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string
}

PingIcon.defaultProps = {
  width: '2em',
  height: '2em',
  className: undefined,
  fill: '#000000'
}

export default PingIcon


