import React from 'react'
import PropTypes from 'prop-types'

function PackageIcon({ height, width, fill, className }) {
  return (
    <svg fill={fill} height={height} width={width} className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve"><path d="M90.342,27.816c-4.539-2.269-34.463-17.232-39-19.5c-0.844-0.422-1.84-0.422-2.684,0l-39,19.5  C8.619,28.337,8,29.486,8,30.5v39c0,1.137,0.642,2.174,1.658,2.684l39,19.5c0.879,0.443,1.884,0.403,2.684,0l39-19.5  C91.358,71.674,92,70.637,92,69.5v-39C92,29.461,91.37,28.332,90.342,27.816z M50,14.354l11.728,5.864L29.437,36.364L17.709,30.5  L50,14.354z M47,84.146l-33-16.5V35.354l33,16.5V84.146z M50,46.646l-13.854-6.927l32.291-16.146L82.291,30.5L50,46.646z M86,67.646  l-33,16.5V51.854l33-16.5V67.646z"></path></svg>
  )
}

PackageIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string
}

PackageIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
  className: undefined
}

export default PackageIcon
