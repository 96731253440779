import React from 'react'
import PropTypes from 'prop-types'

function ClientIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className={className} width={width} height={height} version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <path d="m125.44 94.465c0-3.0703-2.5586-5.6328-5.6328-5.6328h-13.566v-2.8164c0-4.0977-3.3281-7.6797-7.6797-7.6797h-25.602c-0.76953-3.0703-3.3281-5.375-6.3984-6.3984l-0.003906-5.3789h31.488c10.496 0 19.199-8.1914 19.199-18.434 0-7.6797-4.8633-14.336-12.289-17.152 0.25781-1.0234 0.25781-2.0469 0.25781-3.3281 0-9.9844-8.7031-18.176-19.199-18.176-4.3516 0-8.4492 1.2812-11.777 3.8398-4.0938-6.3984-11.773-10.75-20.223-10.75-11.777 0-21.504 8.4492-22.785 19.457-11.52 1.0234-20.48 10.496-20.48 21.762 0 12.543 10.496 23.039 22.785 23.039h27.906v5.375c-3.0703 0.76953-5.375 3.3281-6.3984 6.3984h-25.602c-4.0977 0-7.6797 3.3281-7.6797 7.6797v2.8164l-13.57 0.003906c-3.0703 0-5.6328 2.5586-5.6328 5.6328v25.344c0 2.8125 2.5625 5.375 5.6328 5.375h43.008c3.0703 0 5.6328-2.5586 5.6328-5.6328v-25.344c0-3.0703-2.5586-5.6328-5.6328-5.6328h-24.32v-2.8164c0-1.2812 1.0234-2.5586 2.5586-2.5586h25.602c1.0234 3.8398 4.6094 6.6562 8.9609 6.6562s7.6797-2.8164 8.9609-6.6562h25.602c1.2812 0 2.5586 1.0234 2.5586 2.5586v2.8164h-24.32c-3.0703 0-5.6328 2.5586-5.6328 5.6328v25.344c0 3.0703 2.5586 5.6328 5.6328 5.6328h43.008c3.0703 0 5.6328-2.5586 5.6328-5.6328z"/>
    </svg>
  )
}

ClientIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

ClientIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default ClientIcon