import React, { useState, useEffect, Suspense, lazy } from 'react'
import './App.css'
import {Route} from 'react-router-dom'
import {Switch} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { AuthContext } from './Context/auth'
import Spinner from 'react-bootstrap/Spinner'
import { TokenContext } from './Context/token'
import checkToken from './Functions/checkToken'
import NavigationBar from './Components/navbar' 
import { BrowserRouter as Router } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from './Home'

const Rma = lazy(() => import('./Rma'))
const Ping = lazy(() => import('./Ping'))
const Keys = lazy(() => import('./Keys'))
const Terms = lazy(() => import('./Terms'))
const Users = lazy(() => import('./Users'))
const Cases = lazy(() => import('./Cases'))
const Reset = lazy(() => import('./Reset'))
const Login = lazy(() => import('./Login'))
const Client = lazy(() => import('./Client'))
const Orders = lazy(() => import('./Orders'))
const Owners = lazy(() => import('./Owners'))
const Privacy = lazy(() => import('./Policy'))
const Dealers = lazy(() => import('./Dealers'))
const Billing = lazy(() => import('./Billing'))
const Monitor = lazy(() => import('./Monitor'))
const Control = lazy(() => import('./Control'))
const License = lazy(() => import('./License'))
const FollowUp = lazy(() => import('./FollowUp'))
const Facility = lazy(() => import('./Facility'))
const Tracking = lazy(() => import('./Tracking'))
const Schedule = lazy(() => import('./Schedule'))
const PmDetail = lazy(() => import('./PmDetail'))
const Providers = lazy(() => import('./Providers'))
const Inventory = lazy(() => import('./Inventory'))
const Locations = lazy(() => import('./Locations'))
const Equipment = lazy(() => import('./Equipment'))
const Solutions = lazy(() => import('./Solutions'))
const BulbOrder = lazy(() => import('./BulbOrder'))
const CaseDetail = lazy(() => import('./CaseDetail'))
const ScreenAudit = lazy(() => import('./ScreenAudit'))
const BoothImages = lazy(() => import('./BoothImages'))
const UploadImage = lazy(() => import('./UploadImage'))
const ClosedCases = lazy(() => import('./ClosedCases'))
const AddInventory = lazy(() => import('./AddInventory'))
const LocationsTab = lazy(() => import('./LocationsTab'))
const ResetPassword = lazy(() => import('./ResetPassword'))
const ForgotUsername = lazy(() => import('./ForgotUsername'))
const StatusSections = lazy(() => import('./StatusSections'))
const Troubleshooting = lazy(() => import('./Troubleshooting'))
const EquipmentDetail = lazy(() => import('./EquipmentDetail'))
const ScreenAuditResults = lazy(() => import('./ScreenAuditResults'))
const PerformScreenAudit = lazy(() => import('./PerformScreenAudit'))


const App = () => {

  const existingTokens = JSON.parse(localStorage.getItem('tokens'))
  const [authTokens, setAuthTokens] = useState(existingTokens)
  const [tokenState, setTokenState] = useState(false)

  useEffect(() => {
    const existingTokens = JSON.parse(localStorage.getItem('tokens'))
    let hasToken = false
    if (existingTokens !== null) {
      hasToken = checkToken(existingTokens)
    }
    setTokenState(hasToken)
  }, [tokenState])

  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data))
    setAuthTokens(data)
    localStorage.setItem('tokenStatus', JSON.stringify(true))
  }

  const stateOfToken = () => {
    const existingTokens = JSON.parse(localStorage.getItem('tokens'))
    let hasToken = false
    if (existingTokens !== null) {
      hasToken = checkToken(existingTokens)
    }
    setTokenState(hasToken)
  }


  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <TokenContext.Provider value={{ tokenState, setTokenState: stateOfToken }}>
        <React.Fragment>
          <Router>
            <NavigationBar token={tokenState} />
            <Suspense fallback={<Spinner/>}>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/login' component={Login} />
                <Route path='/terms' component={Terms} />
                <PrivateRoute path='/rma' component={Rma} />
                <Route path='/privacy' component={Privacy} />
                <PrivateRoute path='/keys' component={Keys} />
                <PrivateRoute path='/ping' component={Ping} />
                <PrivateRoute path='/pm' component={Schedule} />
                <Route path='/reset/:token' component={Reset} />
                <PrivateRoute path='/users' component={Users} />
                <PrivateRoute path='/cases' component={Cases} />
                <PrivateRoute path='/client' component={Client} />
                <PrivateRoute path='/orders' component={Orders} />
                <PrivateRoute path='/owners' component={Owners} />
                <PrivateRoute path='/control' component={Control} />
                <PrivateRoute path='/license' component={License} />
                <PrivateRoute path='/dealers' component={Dealers} />
                <PrivateRoute path='/billing' component={Billing} />
                <PrivateRoute path='/monitor' component={Monitor} />
                <PrivateRoute path='/followup' component={FollowUp} />
                <PrivateRoute path='/facility' component={Facility} />
                <PrivateRoute path='/tracking' component={Tracking} />
                <PrivateRoute path='/pmDetail' component={PmDetail} />
                <PrivateRoute path='/location' component={Locations} />
                <PrivateRoute path='/inventory' component={Inventory} />
                <PrivateRoute path='/equipment' component={Equipment} />
                <PrivateRoute path='/providers' component={Providers} />
                <Route path='/bulbOrder/:token' component={BulbOrder} />
                <PrivateRoute path='/solutions' component={Solutions} />
                <Route path='/resetPassword' component={ResetPassword} />
                <PrivateRoute path='/caseDetail' component={CaseDetail} />
                <PrivateRoute path='/boothPics' component={BoothImages} />
                <PrivateRoute path='/locations' component={LocationsTab} />
                <Route path='/forgotUsername' component={ForgotUsername} />
                <PrivateRoute path='/screenaudit' component={ScreenAudit} />
                <PrivateRoute path='/closedCases' component={ClosedCases} />
                <PrivateRoute path='/uploadImage' component={UploadImage} />
                <PrivateRoute path='/statusSections' component={StatusSections} />
                <Route path='/troubleshooting/:token' component={Troubleshooting} />
                <PrivateRoute path='/equipmentDetail' component={EquipmentDetail} /> 
                <PrivateRoute path='/performaudit' component={PerformScreenAudit} />
                <PrivateRoute path='/auditresults' component={ScreenAuditResults} />
                <Route path='/addinventory/:orderLinkNumber' component={AddInventory} />
              </Switch>
            </Suspense>
          </Router>
        </React.Fragment>
      </TokenContext.Provider>
    </AuthContext.Provider>
  )
}

export default App

