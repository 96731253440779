import React from 'react'
import PropTypes from 'prop-types'

function InventoryIcon({ height, width, fill, className }) {
  return (
    <svg fill={fill} height={height} width={width} className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve"><path fillRule="evenodd" clipRule="evenodd" d="M94.558,36.576l-6.562,6.563v30.856c0,3.314-2.686,6-6,6H18.004  c-3.313,0-5.999-2.686-5.999-6V61.137L7.58,65.562c-0.59,0.59-1.547,0.59-2.137,0c-0.59-0.592-0.59-1.549,0-2.139l6.562-6.562  V26.003c0-3.313,2.686-5.999,5.999-5.999h63.991c3.314,0,6,2.686,6,5.999v12.86l4.425-4.424c0.59-0.59,1.547-0.59,2.138,0  C95.147,35.029,95.147,35.986,94.558,36.576z M84.995,43.14l-6.562-6.563c-0.591-0.59-0.591-1.547,0-2.137  c0.591-0.59,1.548-0.59,2.138,0l4.425,4.424v-12.86c0-1.656-1.344-3-3-3H18.004c-1.656,0-3,1.343-3,3v30.858l6.562,6.562  c0.59,0.59,0.59,1.547,0,2.139c-0.59,0.59-1.547,0.59-2.137,0l-4.425-4.426v12.859c0,1.658,1.343,3,3,3h63.991c1.656,0,3-1.342,3-3  V43.14z M66.984,60.861c0.001,0.047,0.014,0.09,0.014,0.137c0,2.762-2.238,5-5,5c-2.76,0-4.999-2.238-4.999-5H50h-1.271H46  c0,2.762-2.238,5-4.999,5s-5-2.238-5-5h-4c-2.208,0-4-1.791-4-4V38.001c0-2.208,1.791-3.999,4-3.999H50  c2.21,0,3.999,1.791,3.999,3.999v5h11.999c2.208,0,3.999,1.791,3.999,4v9.998C69.997,58.865,68.713,60.42,66.984,60.861z   M41.001,58.008c-1.652,0-2.991,1.34-2.991,2.99c0,1.652,1.339,2.992,2.991,2.992c1.651,0,2.991-1.34,2.991-2.992  C43.992,59.348,42.653,58.008,41.001,58.008z M61.998,63.99c1.652,0,2.991-1.34,2.991-2.992c0-1.65-1.339-2.99-2.991-2.99  c-1.65,0-2.99,1.34-2.99,2.99C59.008,62.65,60.348,63.99,61.998,63.99z M64.998,45h-5.999c-1.657,0-3,1.343-3,3c0,1.657,1.343,3,3,3  h5.999c1.656,0,2.999-1.343,2.999-3C67.997,46.343,66.654,45,64.998,45z"></path></svg>
  )
}

InventoryIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string
}

InventoryIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
  className: undefined
}

export default InventoryIcon




