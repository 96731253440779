import React from 'react'
import PropTypes from 'prop-types'

function HomeIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className = {className} width = {width}  height={height} xmlns='http://www.w3.org/2000/svg'  version='1.1' x='0px' y='0px' viewBox='0 0 512 512' enableBackground='new 0 0 512 512'><g><g><polygon points='433.585,177.285 433.585,62.507 375.525,62.507 375.525,119.287 263.453,7.229 256.219,0.009 248.876,7.229     0.013,255.968 42.261,298.217 256.156,84.443 469.724,297.873 511.987,255.625   '></polygon></g><g><polygon points='256.001,139.786 255.953,139.724 78.416,317.186 78.416,511.99 197.082,511.99 197.082,354.996 314.919,354.996     314.919,511.99 433.585,511.99 433.585,317.186 256.063,139.724   '></polygon></g></g></svg>
  )
}

HomeIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
}

HomeIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  width: '2em',
  height: '2em',
}

export default HomeIcon