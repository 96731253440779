import  { useState, useEffect } from 'react'

export default function useWidth () {
  const [width, setWidth] = useState(window.innerWidth)
  
  useEffect(()=>{
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })
  },[])

  return width
}