import React from 'react'
import PropTypes from 'prop-types'

function FollowUpIcon({ height, width, fill }) {
  return (
    <svg height={height} width={width} fill={fill} version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m95.879 145.68c5.9336 0.019531 11.637-2.2812 15.902-6.4023 4.2656-4.125 6.7539-9.7461 6.9375-15.676v-48.801c-0.011719-5.8516-2.3398-11.461-6.4805-15.602-4.1367-4.1367-9.7461-6.4648-15.598-6.4805h-1.5195c-5.8516 0.015625-11.461 2.3438-15.602 6.4805-4.1367 4.1406-6.4688 9.75-6.4805 15.602v48.801c0.18359 5.9297 2.6719 11.551 6.9375 15.676 4.2656 4.1211 9.9727 6.4219 15.902 6.4023z"/>
        <path d="m416.12 145.68c5.9297 0.019531 11.637-2.2812 15.902-6.4023 4.2656-4.125 6.7539-9.7461 6.9375-15.676v-48.801c-0.011719-5.8516-2.3438-11.461-6.4805-15.602-4.1406-4.1367-9.75-6.4648-15.602-6.4805h-1.5195c-5.8516 0.015625-11.461 2.3438-15.598 6.4805-4.1406 4.1406-6.4688 9.75-6.4805 15.602v48.801c0.18359 5.9297 2.6719 11.551 6.9375 15.676 4.2656 4.1211 9.9688 6.4219 15.902 6.4023z"/>
        <path d="m466.08 91.121h-11.117v32.48c-0.27734 13.688-7.7422 26.219-19.645 32.984-11.902 6.7656-26.488 6.7656-38.391 0-11.906-6.7656-19.367-19.297-19.645-32.984v-32.48h-242.56v32.48c-0.27734 13.688-7.7383 26.219-19.645 32.984-11.902 6.7656-26.488 6.7656-38.391 0-11.902-6.7656-19.367-19.297-19.645-32.984v-32.48h-11.117c-10.051 0.019531-19.684 4.0234-26.793 11.129-7.1055 7.1055-11.109 16.738-11.129 26.789v45.359h496v-45.359c-0.019531-10.051-4.0234-19.684-11.129-26.789-7.1094-7.1055-16.742-11.109-26.793-11.129z"/>
        <path d="m8 421.36c0.019531 10.051 4.0234 19.684 11.129 26.789 7.1094 7.1094 16.742 11.109 26.793 11.133h420.16c10.051-0.023438 19.684-4.0234 26.793-11.133 7.1055-7.1055 11.109-16.738 11.129-26.789v-230.96h-496zm380-191.36c0-4.418 3.582-8 8-8h61.281c2.1211 0 4.1562 0.84375 5.6562 2.3438s2.3438 3.5352 2.3438 5.6562v55.121c0 2.1211-0.84375 4.1562-2.3438 5.6562s-3.5352 2.3438-5.6562 2.3438h-61.281c-4.418 0-8-3.582-8-8zm0 115.92c0-4.4219 3.582-8 8-8h61.281c2.1211 0 4.1562 0.83984 5.6562 2.3398s2.3438 3.5352 2.3438 5.6602v55.121-0.003907c0 2.1211-0.84375 4.1562-2.3438 5.6562s-3.5352 2.3438-5.6562 2.3438h-61.281c-4.418 0-8-3.582-8-8zm-115.12-115.92c0-4.418 3.582-8 8-8h61.199c2.125 0 4.1602 0.84375 5.6602 2.3438s2.3398 3.5352 2.3398 5.6562v55.121c0 2.1211-0.83984 4.1562-2.3398 5.6562s-3.5352 2.3438-5.6602 2.3438h-61.199c-4.418 0-8-3.582-8-8zm0 115.92c0-4.4219 3.582-8 8-8h61.199c2.125 0 4.1602 0.83984 5.6602 2.3398s2.3398 3.5352 2.3398 5.6602v55.121-0.003907c0 2.1211-0.83984 4.1562-2.3398 5.6562s-3.5352 2.3438-5.6602 2.3438h-61.199c-4.418 0-8-3.582-8-8zm-115.2-115.92c0-4.418 3.582-8 8-8h61.199c2.1211 0 4.1562 0.84375 5.6562 2.3438 1.5039 1.5 2.3438 3.5352 2.3438 5.6562v55.121c0 2.1211-0.83984 4.1562-2.3438 5.6562-1.5 1.5-3.5352 2.3438-5.6562 2.3438h-61.199c-4.418 0-8-3.582-8-8zm0 115.92c0-4.4219 3.582-8 8-8h61.199c2.1211 0 4.1562 0.83984 5.6562 2.3398 1.5039 1.5 2.3438 3.5352 2.3438 5.6602v55.121-0.003907c0 2.1211-0.83984 4.1562-2.3438 5.6562-1.5 1.5-3.5352 2.3438-5.6562 2.3438h-61.199c-4.418 0-8-3.582-8-8zm-110.96-115.92c0-4.418 3.582-8 8-8h61.281c2.1211 0 4.1562 0.84375 5.6562 2.3438s2.3438 3.5352 2.3438 5.6562v55.121c0 2.1211-0.84375 4.1562-2.3438 5.6562s-3.5352 2.3438-5.6562 2.3438h-61.281c-4.418 0-8-3.582-8-8zm0 115.92c0-4.4219 3.582-8 8-8h61.281c2.1211 0 4.1562 0.83984 5.6562 2.3398s2.3438 3.5352 2.3438 5.6602v55.121-0.003907c0 2.1211-0.84375 4.1562-2.3438 5.6562s-3.5352 2.3438-5.6562 2.3438h-61.281c-4.418 0-8-3.582-8-8z"/>
        <path d="m404 353.92h45.281v39.121h-45.281z"/>
        <path d="m404 238h45.281v39.121h-45.281z"/>
        <path d="m288.88 238h45.191v39.121h-45.191z"/>
        <path d="m288.88 353.92h45.191v39.121h-45.191z"/>
        <path d="m173.68 353.92h45.199v39.121h-45.199z"/>
        <path d="m173.68 238h45.199v39.121h-45.199z"/>
        <path d="m62.719 238h45.281v39.121h-45.281z"/>
        <path d="m62.719 353.92h45.281v39.121h-45.281z"/>
      </g>
    </svg>
  )
}

FollowUpIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

FollowUpIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default FollowUpIcon