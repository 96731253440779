import React from 'react'
import PropTypes from 'prop-types'

function MapIcon({ className, fill, height, width }) {
  return (
    <svg height={height} width={width} fill={fill} className={className} version="1.1" x="0px" y="0px" viewBox="-949 951 100 100"  xmlSpace="preserve"><g><g><g><path d="M-923.6,971.6l-22.9-7.1c-0.4-0.1-0.7,0-1,0.2c-0.3,0.2-0.5,0.6-0.5,0.9v63.7c0,0.5,0.3,0.9,0.8,1.1l22.9,7.1     c0.4,0.1,0.7,0,1-0.2c0.3-0.2,0.5-0.6,0.5-0.9v-63.7C-922.8,972.2-923.1,971.8-923.6,971.6z"></path><path d="M-899,988.9c-3.4,0-6.1,2.7-6.1,6.1c0,3.4,2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1C-892.9,991.6-895.6,988.9-899,988.9z"></path><path d="M-878.2,964.7c-0.2-0.2-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0.1l-40.1,7.1c-0.5,0.2-0.8,0.6-0.8,1.1v63.7     c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.3,1,0.2l40.1-7.1c0.5-0.2,0.8-0.6,0.8-1.1v-63.7C-877.8,965.2-877.9,964.9-878.2,964.7z      M-888.5,1000.3c-1.7,3.3-9,17.5-9,17.5c-0.6,1.3-2.5,1.3-3.1,0c0,0-6.1-12-8.9-17.5c-2.8-5.6-0.6-12.9,5.2-15.8     c5.8-2.9,12.9-0.6,15.8,5.2C-886.8,993.1-886.9,996.9-888.5,1000.3z"></path><path d="M-850.8,971.6l-22.9-7.1c-0.4-0.1-0.7,0-1,0.2c-0.3,0.2-0.5,0.6-0.5,0.9v63.7c0,0.5,0.3,0.9,0.8,1.1l22.9,7.1     c0.4,0.1,0.7,0,1-0.2c0.3-0.2,0.5-0.6,0.5-0.9v-63.7C-850,972.2-850.3,971.8-850.8,971.6z"></path></g></g></g></svg>
  )
}

MapIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string
}

MapIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2.6em',
  width: '2.6em'
}

export default MapIcon