import React from 'react'
import Nav from 'react-bootstrap/Nav'
import UserIcon from '../Icons/UserIcon'
import getUser from '../Functions/getUser'

const UserNav = (props) => {
  
  let user = {role:3, user:'none'}
  
  if (props.token) {
    user = getUser()
  }

  let isAdmin = false
  if (user.role) {
    (user.role === 1 || user.role === 8 || user.role === 5 || user.role === 3) ? isAdmin = true : isAdmin = false
  }
 
  return isAdmin ? 
    <Nav.Item className='d-lg-none alignment'><UserIcon height='1.5em' width='1.5em' fill='#FFA500'></UserIcon><Nav.Link style={{color:'#d8d8de'}} href='/users'>&ensp;Users</Nav.Link></Nav.Item> :
    null
}

export default UserNav 