import React from 'react'
import PropTypes from 'prop-types'

function CaseIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className={className} width={width} height={height} xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 846.66 846.66' x='0px' y='0px' fillRule='evenodd' clipRule='evenodd'><defs></defs><g><path d='M497.19 38.08c66.1,0 119.69,53.59 119.69,119.7 0,71.16 -49.21,111.39 -78.28,175.84l0 13.8 -82.83 0 0 -13.8c-29.06,-64.45 -78.27,-104.68 -78.27,-175.84 0,-66.11 53.59,-119.7 119.69,-119.7zm43.13 513.44c58.67,-36.09 178.87,-112.31 237.53,-148.4 24.03,-13.87 53.66,25.11 37.42,41.35l-223.54 208.78 -311.17 10.82 -25.15 13.36 21.18 21.18 -109.94 109.95 -139.91 -139.91 109.94 -109.95 23.99 23.99c148.31,-149.65 119.95,-114.58 385.68,-114.58 48.65,0 45.21,53.97 -7.58,59.32l-154.56 0 156.11 24.09zm-403.64 63.32l-53.8 53.81 83.77 83.77 53.8 -53.81 -83.77 -83.77zm401.92 -251.53l0 11.51 -82.83 0 0 -11.51 82.83 0zm0 27.4l0 25.74 -50.29 0c-15.56,0 -28.67,-11.08 -31.81,-25.74l82.1 0zm-32.92 -57.09l0 -126.34c8.52,-3.38 14.55,-11.7 14.55,-21.43 0,-12.72 -10.31,-23.04 -23.04,-23.04 -12.72,0 -23.04,10.32 -23.04,23.04 0,9.73 6.02,18.04 14.55,21.43l0 126.34 16.98 0z'></path></g></svg>
  )
}

CaseIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

CaseIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default CaseIcon

