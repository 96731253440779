import React from 'react'
import PropTypes from 'prop-types'

function ServiceIcon({ className, fill, height, width }) {
  return (
    <svg fill={fill} className={className} height={height} width={width} xmlns='http://www.w3.org/2000/svg'  version='1.1' x='0px' y='0px' viewBox='0 0 100 100' ><path d='M68.5,70.2'></path><path d='M90.4,64.2l-19.2,5.4l0,0.1c0.5,1.9,0.2,4-0.8,5.7c-1,1.9-2.7,3.2-4.8,3.8c-2,0.6-4.2,0.3-6.1-0.7l-11.1-6.2  l1-1.9l11.1,6.2c1.4,0.8,2.9,0.9,4.4,0.5c1.5-0.4,2.7-1.4,3.5-2.8c0.4-0.7,0.6-1.5,0.7-2.3c0.3-1.9-0.6-3.9-2.3-4.8l-26.9-15  c0,0-2.8-1.9-6.6-0.8c0,0,0,0-0.1,0c-0.7,0.1-29.6,8.4-30.8,8.7l-0.1,0L8.4,81l19.3-5.5c2,1.2,16.7,10.3,18.5,11.4  c3.3,1.9,7.3,0.9,8.2,0.6c0.1,0,39.4-11.2,39.4-11.2c2.7-0.8,5-4.4,3.9-8.1C96.8,65.1,93.5,63.3,90.4,64.2z'></path><path d='M92.1,29.9h-3.5c-0.5-2.1-1.3-4-2.4-5.8l2.8-2.8c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8l-3.9-3.9  c-0.4-0.4-1.1-0.4-1.5,0l-2.9,2.9c-1.6-1-3.4-1.7-5.2-2.2v-3.7c0-0.6-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1v3.6  c-2,0.4-3.8,1.2-5.4,2.2l-3.1-3.1c-0.4-0.4-1.1-0.4-1.5,0l-3.9,3.9c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8l3,3  c-1.2,1.8-2,3.7-2.5,5.9H51c-0.6,0-1.1,0.5-1.1,1.1v5.4c0,0.6,0.5,1.1,1.1,1.1h3.5c0.4,1.9,1.2,3.7,2.2,5.3l-2.8,2.8  c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8l3.9,3.9c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l2.7-2.7c1.8,1.2,3.8,2,6,2.5v3.1  c0,0.6,0.5,1.1,1.1,1.1h5.4c0.6,0,1.1-0.5,1.1-1.1v-3.2c2.1-0.5,4-1.3,5.7-2.4l2.3,2.3c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3  l3.8-3.8c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8l-2.4-2.4c1-1.6,1.7-3.4,2.2-5.4h3.5c0.6,0,1.1-0.5,1.1-1.1v-5.4  C93.1,30.4,92.7,29.9,92.1,29.9z M71.5,44.4c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7s10.7,4.8,10.7,10.7S77.4,44.4,71.5,44.4z  '></path></svg>
  )
}

ServiceIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
}

ServiceIcon.defaultProps = {
  width: '2em',
  height: '2em',
  className: undefined,
  fill: '#000000',
}

export default ServiceIcon