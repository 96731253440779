import React from 'react'
import PropTypes from 'prop-types'

function ProjIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className={className} height={height} width={width} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' x='0px' y='0px'><g><circle cx='10' cy='5' r='1'></circle><path d='M15,4H13.858A3.981,3.981,0,0,0,6.142,4H1A.99.99,0,0,0,0,5v6a.99.99,0,0,0,1,1v1.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V12h8v1.5a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5V12a.99.99,0,0,0,1-1V5A.99.99,0,0,0,15,4ZM10,2A3,3,0,1,1,7,5,3,3,0,0,1,10,2ZM3.5,9h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm0-2h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Z'></path></g></svg>
  )
}

ProjIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
}

ProjIcon.defaultProps = {
  width: '2em',
  height: '2em',
  className: undefined,
  fill: '#000000',
}

export default ProjIcon

