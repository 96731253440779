import React from 'react'
import PropTypes from 'prop-types'

function MenuIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} viewBox="0 0 32 32" x="0px" y="0px"><g><rect x="2" y="13" width="28" height="6" rx="2.22" ry="2.22"></rect><rect x="2" y="24" width="28" height="6" rx="2.22" ry="2.22"></rect><rect x="2" y="2" width="28" height="6" rx="2.22" ry="2.22"></rect></g></svg>
  )
}



MenuIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

MenuIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default MenuIcon


