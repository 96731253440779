import React from 'react'
import PropTypes from 'prop-types'

function RmaIcon({ className, fill, height, width }) {
  return (
    <svg height={height} width={width} fill={fill} className={className}  viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m374.29 711.28c32.484 65.766 91.734 114.38 162.61 133.36-21.047-41.766-34.453-86.906-39.609-133.36z"/>
      <path d="m827.39 711.28h-123.24c-5.2031 46.359-18.562 91.406-39.375 133.13 70.781-18.938 130.03-67.453 162.61-133.13z"/>
      <path d="m684.94 362.26c-6.6562-2.4375-13.547-4.4531-20.391-6.4688 21 41.719 34.453 86.859 39.609 133.31h123c-29.109-59.156-80.109-104.62-142.22-126.84z"/>
      <path d="m536.68 355.78c-70.734 19.172-129.94 67.688-162.61 133.31h123.24c5.2969-46.406 18.609-91.5 39.375-133.31z"/>
      <path d="m585.19 843.42c37.828 40.828 70.219-77.578 78.188-132.1h-125.26c9.6562 61.406 27.047 110.86 47.062 132.1z"/>
      <path d="m600.71 347.72c-6.1406 0.98438-11.719 4.3125-15.516 9.2812-20.016 21.234-37.359 70.688-47.062 132.1h125.26c-9.7031-61.406-27.047-110.91-47.062-132.1h-0.046875c-3.7969-4.9688-9.375-8.2969-15.562-9.2812z"/>
      <path d="m348.84 618.56c1.3125 17.766 4.5 35.297 9.4688 52.359h134.34c-4.0312-47.062-4.0312-94.359 0-141.42h-134.34c-8.4375 28.875-11.625 59.062-9.4688 89.062z"/>
      <path d="m850.6 564.24c-1.5938-11.766-4.0781-23.344-7.4531-34.734h-134.34c4.0312 47.062 4.0312 94.359 0 141.42h134.34c10.172-34.594 12.703-71.016 7.4531-106.69z"/>
      <path d="m530.02 600.19c0 24.234 1.0312 48.094 3.2344 70.688l134.95 0.046875c2.2031-22.641 3.2344-46.453 3.2344-70.688s-1.0312-48.094-3.2344-70.688h-134.95c-2.2031 22.594-3.2344 46.406-3.2344 70.641z"/>
      <path d="m600.71 75c-29.953 0-59.906 2.5781-89.484 7.6875l0.42188 69.703v-0.046875c29.344-5.8125 59.156-8.7188 89.062-8.6719 451.6 1.5938 627.1 592.6 248.86 838.92-7.3125 5.6719-17.578 5.5781-24.797-0.1875-6.7969-3.8438-8.9531-16.969-11.906-23.625l-69.469 99.797 117.14 32.109c-0.75-2.3906-4.4062-11.531-4.8281-13.547h-0.046875c-2.7656-10.172 2.6719-20.766 12.516-24.469 455.26-276.1 265.92-972.52-267.47-977.68z"/>
      <path d="m144.19 600.19c-0.28125-101.95 33.938-201 97.172-280.97 3.2344-5.0625 8.5781-8.3906 14.531-9.1875s11.953 1.125 16.359 5.1562l2.4375 2.2031 13.125-115.73-114.14 21.422c11.016 11.016 33.656 24.703 22.031 42.844-276.98 331.08-44.859 844.82 384.84 859.08v-68.672c-117.42-5.3438-228.28-55.734-309.56-140.68-81.234-84.938-126.66-197.9-126.79-315.47z"/>
    </svg>
  )
}

RmaIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string
}

RmaIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2.6em',
  width: '2.6em'
}

export default RmaIcon