import React from 'react'
import PropTypes from 'prop-types'

function TechnicianIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve"><g><polygon points="82.3,88.4 84.5,87.1 84.5,84.3 82.3,83.1 80.1,84.3 80.1,87.2  "></polygon><path d="M94.5,81c-1.1-3.2-4.1-4.4-7.2-5.5l0,10.4c0,2.7-2.2,4.9-4.8,4.9c-1.3,0-2.6-0.5-3.5-1.4c-0.9-0.9-1.4-2.1-1.4-3.4   l-0.1-14.3c-5-2-10.7-4.2-11.5-4.4c-1.5-1.1-3-4.6-3.9-6.3c-0.8-0.1-1.6-0.2-2.5-0.4c0.1-2.7,1.8-2.9,2.5-4.9   c0.6-1.8,0.1-4.2,1-5.9c0.6-1.2,1.5-0.6,2.2-1.6c0.7-0.9,0.8-2.9,1-4c0.4-2,0.6-4.4,0.9-6.6c0.2-1.2-1-1.7-1-1.7H32.3   c0,0-1.1,0.3-0.9,2.5c0.2,2.5,0.2,8.7,2.7,10.1c1.7,1,1.7,5,2.6,7.8c0.7,2.4,2.5,1.7,3,4l-2.3,0.5c-0.8,1.8-2.3,5.3-3.9,6.3   c-1.3,0.4-13.4,5.1-18,7c-4.1,1.7-9.1,2.4-10.6,6.7c0,2.9-0.3,9.9-0.2,13.7h90C94.7,90.9,94.5,83.9,94.5,81z"></path><path d="M71.7,30.3h-3.4V27c0-10.3-7.3-18.7-17.2-19.5c-0.2-1.3-0.9-2.2-1.6-2.2c-0.8,0-1.4,0.9-1.6,2.2   C37.8,8.3,30.5,16.7,30.5,27v3.2h-3.4v3.4h44.6V30.3z"></path><path d="M87.2,75.5l-0.1-23.6c0-0.1,0-0.2,0-0.3c3.1-1.8,5.2-5.2,5.2-9c0-3.8-2.1-7.2-5.2-8.9l0,8.3L82,45L76.8,42l0-8.3   c-3.1,1.8-5.2,5.1-5.1,9c0,4,2.3,7.5,5.7,9.2c0,0,0,0,0,0l0.1,19.7c2.5,1,4.9,2,6.4,2.6C85,74.8,86.1,75.1,87.2,75.5z"></path></g></svg>
  )
}



TechnicianIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

TechnicianIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default TechnicianIcon


